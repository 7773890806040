import * as turf from '@turf/turf';
import { nanoid } from 'nanoid';
import React, { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled, { useTheme } from 'styled-components';
import { calculateRotationAngleToNorth, customRectangleGrid } from '../../../helpers/utils';

const SolarPanels = ({ map }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const solarPanelPolygonReferencesRef = useRef(new Map());
  const solarPanelPolygonsRef = useRef([]);

  const { solarOptions, selectedSolarOption } = useSelector(state => state.quote);
  const { roof_segments, property } = solarOptions || {};

  const { class_type } = property || {};

  const { config } = selectedSolarOption || {};
  const { panels: panelsToShow, segments } = config || {};

  const customGap = useMemo(() => {
    const gap = class_type === 'RESIDENTIAL' ? 20 : 50;
    return parseFloat(gap / 100).toFixed(3);
  }, [class_type]);

  const drawGridOnSegment = (segmentId, segment, panels = [], panelDetails = null) => {
    const { boundary, pitch, orientation, grid_alignment, alignment_line } = segment;

    const firstPanelOrientation = orientation ? orientation : panels[0]?.orientation || 'PORTRAIT';

    const { height: customHeight, width: customWidth } = panelDetails;
    const panelIds = panels.map(panel => panel.id);

    let panelHeight = customHeight;
    let panelWidth = customWidth;
    if (firstPanelOrientation === 'LANDSCAPE') {
      panelHeight = customWidth;
      panelWidth = customHeight;
    }
    const pitchInRadians = pitch * (Math.PI / 180);
    const pitchElevation = parseFloat(panelWidth * Math.cos(pitchInRadians)).toFixed(2);
    panelWidth = pitchElevation > 0 ? pitchElevation : 0.03;

    const paths = boundary.map(bound => ({ lat: bound.lat, lng: bound.lon }));
    let angleOfLine = 0;

    let isGridAlignmentAvailable = parseFloat(grid_alignment) >= 0;

    if (alignment_line) {
      const { angle, firstPoint, secondPoint } = calculateRotationAngleToNorth(paths);
      angleOfLine = angle;
    } else if (isGridAlignmentAvailable) {
      angleOfLine = parseFloat(grid_alignment) + 90;
    } else {
      angleOfLine = turf.bearing(
        [boundary[0].lon, boundary[0].lat],
        [boundary[boundary.length - 2].lon, boundary[boundary.length - 2].lat],
      );
    }

    const turfPolygon = turf.polygon([boundary.map(bound => [bound.lon, bound.lat])]);

    const innerTurfPolygon = turf.buffer(turfPolygon, -customGap, { units: 'meters' });
    if (!innerTurfPolygon) {
      return null;
    }

    const rotatedPolygon = turf.transformRotate(innerTurfPolygon, -angleOfLine, {
      pivot: turf.center(innerTurfPolygon.geometry),
    });

    const rectangleGrid = customRectangleGrid(
      turf.bbox(rotatedPolygon),
      parseFloat(customGap),
      parseFloat(panelWidth),
      parseFloat(panelHeight),
      {
        mask: rotatedPolygon,
      },
    );

    const pivot = turf.center(innerTurfPolygon.geometry);

    let intersectingPolygons = rectangleGrid.features
      .map(feature => {
        const rotatedFeature = turf.transformRotate(feature, angleOfLine, {
          pivot: pivot,
        });
        if (turf.booleanContains(innerTurfPolygon, rotatedFeature)) {
          return rotatedFeature;
        }
      })
      .filter(Boolean);

    if (panelIds.length > 0) {
      let panelCenterPoints = panels
        .map(panel => ({
          id: panel?.id,
          isNew: panel?.isNew,
          row: panel?.row,
          column: panel?.column,
          point: turf.point([panel.center.lon, panel.center.lat]),
        }))
        .sort((a, b) => a.row - b.row || a.column - b.column);

      intersectingPolygons = intersectingPolygons.map(polygon => {
        const { properties } = polygon || {};
        const { row, column } = properties || {};
        const isRowColumnMatch = panelCenterPoints.find(p => p.row === row && p.column === column);
        if (isRowColumnMatch) {
          panelCenterPoints = panelCenterPoints.filter(p => p.id !== isRowColumnMatch.id);
          return { ...polygon, id: isRowColumnMatch.id, isNew: isRowColumnMatch.isNew };
        }
        return { ...polygon };
      });

      intersectingPolygons = intersectingPolygons.map(polygon => {
        if (polygon.id) return polygon;
        const { properties } = polygon || {};
        const { row, column } = properties || {};
        const isRowColumnMatch = panelCenterPoints.find(p => p.row === row && p.column === column);
        const panelInPolygon = panelCenterPoints.find(p => turf.booleanPointInPolygon(p.point, polygon));
        if (panelInPolygon && !isRowColumnMatch) {
          panelCenterPoints = panelCenterPoints.filter(p => p.id !== panelInPolygon.id);
          return { ...polygon, id: panelInPolygon.id, isNew: panelInPolygon.isNew };
        }
        return { ...polygon };
      });
    }

    const data = {
      segmentId: segmentId,
      id: nanoid(),
      gridPolygons: intersectingPolygons.filter(p => p && p.id),
    };

    return data;
  };

  useEffect(() => {
    if (roof_segments && roof_segments.length > 0 && map && segments) {
      const solarPanelPolygons = [];
      const solarPanelPolygonReferences = solarPanelPolygonReferencesRef.current;
      let panelsCount = 0;

      segments?.forEach(segment => {
        const { segment_id, panels } = segment;
        const roofSegmentSummary = roof_segments.find(segment => segment.id === segment_id);

        const {
          panels: roofAllPanels,
          boundary,
          panel_grid,
          pitch_degrees: pitch,
          azimuth_degrees: azimuth,
          grid_alignment,
          alignment_line,
        } = roofSegmentSummary || {};
        let roofPanels = roofAllPanels?.slice(0, panels);
        roofPanels = roofPanels?.map((panel, index) => ({ ...panel, id: `${segment_id}-${index}` }));

        if (panel_grid) {
          if (roofPanels?.length > 0) {
            const firstPanel = roofPanels?.[0];
            const { height, width, orientation } = firstPanel;
            const data = drawGridOnSegment(
              segment_id,
              { boundary, pitch, orientation, azimuth, grid_alignment, alignment_line },
              roofPanels,
              {
                height,
                width,
              },
            );

            const { gridPolygons } = data || {};
            if (gridPolygons?.length) {
              gridPolygons.map((panelPolygon, index) => {
                const { id, geometry } = panelPolygon || {};
                const { coordinates } = geometry || {};
                const solarPanelId = id || `${segment_id}-${index}`;
                const paths = coordinates?.[0].map(coord => ({
                  lat: coord[1],
                  lng: coord[0],
                }));
                if (!solarPanelPolygonReferences.has(solarPanelId)) {
                  solarPanelPolygonReferences.set(
                    solarPanelId,
                    new google.maps.Polygon({
                      map: map,
                      fillColor: theme.natural_900,
                      fillOpacity: 0.9,
                      strokeWeight: 0.8,
                      strokeColor: '#FFFFFF',
                      strokeOpacity: 1,
                      strokePosition: google.maps.StrokePosition.INSIDE,
                      geodesic: false,
                      paths: paths,
                    }),
                  );
                }
                const polygon = solarPanelPolygonReferences.get(solarPanelId);
                polygon?.setMap(map);

                solarPanelPolygons.push(polygon);
              });
            }
          }
        } else {
          roofPanels?.forEach(solarPanel => {
            const { center: panelCenter, width: panelWidth, height: panelHeight } = solarPanel;
            const solarPanelId = `${panelCenter.lat}-${panelCenter.lon}`;
            let height = parseFloat(parseFloat(panelHeight) / 2).toFixed(3);
            let width = parseFloat(parseFloat(panelWidth) / 2).toFixed(3);

            if (solarPanel.orientation === 'LANDSCAPE') {
              const previousHeight = height;

              height = width;
              width = previousHeight;
            }

            const angle = roofSegmentSummary.azimuth_degrees;

            if (!solarPanelPolygonReferences.has(solarPanelId)) {
              const center = {
                lat: panelCenter.lat,
                lng: panelCenter.lon,
              };

              const top = google.maps.geometry.spherical.computeOffset(center, height, angle + 0);
              const right = google.maps.geometry.spherical.computeOffset(center, width, angle + 90);
              const left = google.maps.geometry.spherical.computeOffset(center, width, angle + 270);

              const topRight = google.maps.geometry.spherical.computeOffset(top, width, angle + 90);
              const bottomRight = google.maps.geometry.spherical.computeOffset(right, height, angle + 180);
              const bottomLeft = google.maps.geometry.spherical.computeOffset(left, height, angle + 180);
              const topLeft = google.maps.geometry.spherical.computeOffset(left, height, angle + 0);

              solarPanelPolygonReferences.set(
                solarPanelId,
                new google.maps.Polygon({
                  map: map,
                  fillColor: theme.natural_900,
                  fillOpacity: 0.9,
                  strokeWeight: 0.8,
                  strokeColor: '#FFFFFF',
                  strokeOpacity: 1,
                  strokePosition: google.maps.StrokePosition.INSIDE,
                  geodesic: false,
                  paths: [topRight, bottomRight, bottomLeft, topLeft],
                }),
              );
            }
            const polygon = solarPanelPolygonReferences.get(solarPanelId);
            polygon?.setMap(map);

            solarPanelPolygons.push(polygon);
          });
        }
        solarPanelPolygonsRef.current = solarPanelPolygons;
        solarPanelPolygonReferencesRef.current = solarPanelPolygonReferences;
        panelsCount += roofSegmentSummary?.panels?.length;
      });
    }

    return () => {
      solarPanelPolygonsRef.current.forEach(polygon => polygon.setMap(null));
    };
  }, [panelsToShow, roof_segments, map, segments]);

  return (
    <>
      {/* <SolarPanelWrapper
        className="bg-white radius-2 pxy-2 cursor"
        as={'button'}
        onClick={e => setShowPanels(!showPanels)}>
        <SolarPanelIcon
          width={16}
          height={16}
          className={classNames(showPanels ? 'primary-500-text' : 'natural-500-text')}
        />
      </SolarPanelWrapper> */}
      <div className="absolute p-4 right-0 bottom-0 left-0 bg-natural-900 opacity-8">
        <div>
          <p className="inter-400-text font-12 text-white line-height-150">{t('PANEL_PLACEMENT_INFO')}</p>
        </div>
      </div>
    </>
  );
};

const SolarPanelWrapper = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
`;

export default SolarPanels;
